(function() {
    'use strict';

    angular
        .module('mlgomasApp')
        .controller('FuelleModeloDialogController', FuelleModeloDialogController);

    FuelleModeloDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
                                            'FuelleModelo', 'FindAllWithoutPagination', 'ModelosWithoutPagination'];

    function FuelleModeloDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, FuelleModelo,
                                           FindAllWithoutPagination, ModelosWithoutPagination) {
        var vm = this;

        vm.fuelleModelo = entity;
        vm.clear = clear;
        vm.save = save;
        vm.fuelles = FindAllWithoutPagination.query();
        vm.modelos = ModelosWithoutPagination.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.fuelleModelo.id !== null) {
                FuelleModelo.update(vm.fuelleModelo, onSaveSuccess, onSaveError);
            } else {
                FuelleModelo.save(vm.fuelleModelo, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('mlgomasApp:fuelleModeloUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
