/**
 * Created by milton on 23/06/17.
 */
(function() {
    'use strict';

    angular
        .module('mlgomasApp')
        .controller('NovedadesController', NovedadesController);

    NovedadesController.$inject = ['GetAllNoticiasWithThumbnailOnly', 'ParseLinks', 'paginationConstants'];

    function NovedadesController(GetAllNoticiasWithThumbnailOnly, ParseLinks, paginationConstants) {
        var vm = this;

        //Llamada a funciones.
        loadAll();

        //Funciones
        function loadAll () {

            vm.itemsPerPage = 9;

            var pageable = {
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            };

            GetAllNoticiasWithThumbnailOnly.query(pageable, onSuccess);

            function sort() {
                vm.predicate = 'fecha';
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'fecha') {
                    result.push('fecha');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.noticias = data;
                //vm.page = pagingParams.page;
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }
})();
