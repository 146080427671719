(function() {
    'use strict';
    angular
        .module('mlgomasApp')
        .factory('SendMessage', SendMessage)
        .factory('SendPedido', SendPedido);

    SendMessage.$inject = ['$resource', 'DateUtils'];
    SendPedido.$inject = ['$resource', 'DateUtils'];

    function SendMessage ($resource, DateUtils) {
        var resourceUrl =  'api/contact/sendMessage';

        return $resource(resourceUrl, {}, {
            'send': { method: 'POST'} 
        });
    }
    function SendPedido ($resource) {
        var resourceUrl =  'api/contact/sendPedido';

        return $resource(resourceUrl, {}, {
            'send': { method: 'POST'}
        });
    }
})();
