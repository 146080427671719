/**
 * Created by milton on 26/06/17.
 */
(function() {
    'use strict';

    angular
        .module('mlgomasApp')
        .controller('NoticiaViewController', NoticiaViewController);

    NoticiaViewController.$inject = ['Noticia', '$stateParams', 'GetAllNoticiasWithThumbnailOnly', 'ParseLinks'];

    function NoticiaViewController(Noticia, $stateParams, GetAllNoticiasWithThumbnailOnly, ParseLinks) {

        var vm = this;

        //Llamadas a funciones
        loadAll();

        //Declaracion de funciones
        function loadAll(){

            Noticia.get({id: $stateParams.id}, function(result){
                vm.noticia = result;
            });

            vm.itemsPerPage = 9;

            var pageable = {
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            };

            GetAllNoticiasWithThumbnailOnly.query(pageable, onSuccess);

            function sort() {
                vm.predicate = 'fecha';
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'fecha') {
                    result.push('fecha');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.noticias = data;
                //vm.page = pagingParams.page;
            }
        }


    }
})();
