(function() {
    'use strict';

    angular
        .module('mlgomasApp')
        .controller('WebImageDetailController', WebImageDetailController);

    WebImageDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'WebImage'];

    function WebImageDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, WebImage) {
        var vm = this;

        vm.webImage = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('mlgomasApp:webImageUpdate', function(event, result) {
            vm.webImage = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
