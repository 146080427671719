/**
 * Created by milton on 29/06/17.
 */
(function() {
    'use strict';

    angular
        .module('mlgomasApp')
        .controller('DistribuidoresController', DistribuidoresController);

    DistribuidoresController.$inject = ['SendMessage', '$timeout'];

    function DistribuidoresController(SendMessage, $timeout) {

        var vm = this;

        vm.email = null;

        //Asignacion de funciones
        vm.send = send;

        //Llamadas a funciones


        //Declaracion de funciones
        function send() {
            vm.email.subject = "Consulta de distribuidor."; 
            vm.email.distribuidor = true;
            console.log(vm.email);
            SendMessage.send(vm.email, function () {
                vm.email = null;
                vm.sent = true;
                $timeout(function () {
                    vm.sent = false;
                }, 3000);
            });
        }

    }
})();
