(function() {
    'use strict';

    angular
        .module('mlgomasApp')
        .controller('ModeloDialogController', ModeloDialogController);

    ModeloDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Modelo', 'Marca'];

    function ModeloDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Modelo, Marca) {
        var vm = this;

        vm.modelo = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.marcas = Marca.query();

        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.modelo.id !== null) {
                Modelo.update(vm.modelo, onSaveSuccess, onSaveError);
            } else {
                Modelo.save(vm.modelo, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('mlgomasApp:modeloUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.creationDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
