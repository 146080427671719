(function() {
    'use strict';

    angular
        .module('mlgomasApp')
        .controller('ProductosController', ProductosController);

    ProductosController.$inject = ['FindAllWithoutPagination', 'FindAllCompleteFuelleWithoutPagination', '$scope', 'paginationConstants', 'ModelosByMarca', 'Linea','Marca', 'FindAllCompleteFuelle','$rootScope', 'ParseLinks', '$timeout'];

    function ProductosController(FindAllWithoutPagination, FindAllCompleteFuelleWithoutPagination, $scope, paginationConstants, ModelosByMarca, Linea, Marca, FindAllCompleteFuelle,$rootScope, ParseLinks, $timeout) {
        var vm = this;
        vm.loading = false;
        vm.estado = false;
        vm.pulsar = pulsar;
        vm.printScreen = printScreen;
        vm.printFullCatalog = printFullCatalog;
        vm.showCarrito = showCarrito;
        vm.setModelosModal = setModelosModal;
        vm.addFuelleToCarrito = addFuelleToCarrito;
        vm.addKitToCarrito = addKitToCarrito;
        vm.removeFuelleFromCarrito = removeFuelleFromCarrito;
        vm.removeKitFromCarrito = removeKitFromCarrito;
        vm.confirmCarrito = confirmCarrito;
        vm.transition = transition;
        vm.loadAll = loadAll;
        vm.updateModelos = updateModelos;
        vm.cleanModelo = cleanModelo;
        vm.cleanLinea = cleanLinea;
        vm.cleanMarca = cleanMarca;
        vm.loadImage = loadImage;
        vm.fuelleComparator = fuelleComparator;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = 1;
        vm.fuelles = [];

        vm.filterDTO = {
            searchString: '',
            lineaId: '',
            modeloId: '',
            marcaId: ''
        };

        loadEntities();
        setupCarrito();

        $( document ).ready(function() {
            window.scrollTo(0,0);
            setStyle();
        });

        // ------------ Functions ------------
        function loadEntities() {
            var pageable = {
                page: vm.page - 1,
                size: 10000,
                sort: 'asc'
            };
            vm.marcas = Marca.query(pageable);
            vm.lineas = Linea.query(pageable);
        }

        function setStyle() {
            var height = $('.resultados').height();
            var carrito = $('.carrito');
            if(height > 500){
                carrito.css({'height': height + 'px'});
            }
        }
        function transition () {
            vm.pageable = {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            };
            loadAll();
        }
        function setupCarrito() {
            if($rootScope.carrito != null ){
                if(($rootScope.carrito.fuelleDTOs != null && $rootScope.carrito.fuelleDTOs.length != 0)
                    || ($rootScope.carrito.kitDTOs != null && $rootScope.carrito.kitDTOs.length != 0)){
                    vm.carrito = angular.copy($rootScope.carrito);
                }else{
                    vm.carrito = {
                        fuelleDTOs: [],
                        kitDTOs: []
                    };
                }
            }else{
                vm.carrito = {
                    fuelleDTOs: [],
                    kitDTOs: []
                };
            }
        }
        function loadAll() {
            setupCarrito();
            vm.filterDTOCopy = angular.copy(vm.filterDTO);
            vm.loading = true;

            FindAllCompleteFuelle.query(vm.pageable, vm.filterDTO, onSuccess);
            function onSuccess(data, headers) {
                vm.loading = false;
                vm.fuelles = data;
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.page = vm.page ;
                vm.queryCount = vm.totalItems;
            }
        }

        function pulsar(evt) {

            var evt = (evt) ? evt : ((event) ? event : null);
            var node = (evt.target) ? evt.target : ((evt.srcElement) ? evt.srcElement : null);
            if ((evt.keyCode == 13) && (node.type == "text")) { vm.loadAll();}
        }

        function updateModelos() {
            vm.modelos = ModelosByMarca.query({id: vm.filterDTO.marcaId});
        }

        // BUSCADOR
        function cleanMarca() {
            vm.filterDTO.marcaId = null;
            cleanModelo();
        }
        function cleanLinea() {
            vm.filterDTO.lineaId = null;
        }
        function cleanModelo() {
            vm.filterDTO.modeloId = null;
        }

        // CARRITO
        function showCarrito() {
            var carrito = $('.carrito');
            carrito.toggle();
        }
        function setModelosModal(modeloDTOs) {
            vm.modelos = modeloDTOs;
        }
        function addFuelleToCarrito(obj) {
            var flag = true;
            if(obj.cantidad > 0){
                vm.carrito.fuelleDTOs = $.grep(vm.carrito.fuelleDTOs , function (o) {
                    if(o.id == obj.id){
                        o.cantidad = o.cantidad + obj.cantidad;
                        flag = false;
                    }

                    return o;
                });
                //De esta forma no lo agrega, simplemente modifica la cantidad
                if(flag){
                    vm.carrito.fuelleDTOs.push(angular.copy(obj));
                }

                obj.cantidad = null;

                $rootScope.carrito = angular.copy(vm.carrito);
                $.notify("Agregado al pedido.", "success");
            }
        }
        function addKitToCarrito(cod, kit, cant, f,  index) {
            var flag = true;

            var obj = {
                cantidad: cant,
                cod: cod,
                kit: kit
            };


            if(cant > 0){
                vm.carrito.kitDTOs = $.grep(vm.carrito.kitDTOs, function (o) {
                    if(o.cod == obj.cod){
                        o.cantidad = o.cantidad + obj.cantidad;
                        flag = false;
                    }
                    return o;
                });

                //De esta forma no lo agrega, simplemente modifica la cantidad
                if(flag){
                    vm.carrito.kitDTOs.push(angular.copy(obj));
                }

                obj.cantidad = null;

                $rootScope.carrito = angular.copy(vm.carrito);
                $.notify("Agregado al pedido.", "success");
            }

        }
        function removeFuelleFromCarrito(obj){
            vm.carrito.fuelleDTOs = $.grep(vm.carrito.fuelleDTOs, function (o) {
                if(o.id != obj.id){
                    return o;
                }
            });

            $rootScope.carrito = angular.copy(vm.carrito);
            $.notify("Eliminado del pedido.", "error");
        }
        function removeKitFromCarrito(obj){
            vm.carrito.kitDTOs = $.grep(vm.carrito.kitDTOs, function (o) {
                if(o.cod != obj.cod){
                    return o;
                }
            });

            $rootScope.carrito = angular.copy(vm.carrito);
            $.notify("Eliminado del pedido.", "error");
        }
        function confirmCarrito() {
            $rootScope.carrito = vm.carrito;
        }

        //Funciones para imprimir los catalogos.
        //Imprime los fuelles del resultado de busqueda
        function printScreen() {
            $('.printing').show();
            $('body').css('overflow','hidden');
            vm.printScreenOnly = false;
            vm.printAll = true;
            $scope.temp = false;

            FindAllCompleteFuelleWithoutPagination.query(vm.filterDTOCopy, onSuccess);
            function onSuccess(data) {
                vm.loading = false;
                vm.fuellesAll = data;
                $('.printing').hide();
                $('body').css('overflow','visible');

                $scope.$watch('fuellesAll', function () {
                    vm.fuellesAll.$promise.then(function () {
                        window.print();
                    })
                })
            }
        }
        //Imprime el catalogo completo.
        function printFullCatalog() {
            $('.printing').show();
            $('body').css('overflow','hidden');
            vm.printScreenOnly = false;
            vm.printAll = true;
            $scope.temp = false;

            FindAllWithoutPagination.query(onSuccess);
            function onSuccess(data) {
                vm.loading = false;
                vm.fuellesAll = data;
                $('.printing').hide();
                $('body').css('overflow','visible');

                $scope.$watch('fuellesAll', function () {
                    vm.fuellesAll.$promise.then(function () {
                        window.print();
                    })
                })
            }
        }

        function loadImage(f) {
            vm.fuelleImagen = f;
        }

        function fuelleComparator(a, b) {
            if (a.type === 'object' && b.type === 'object') {
                if (a.value.codigo && !b.value.codigo) {
                    return -1;
                } else if (!a.value.codigo && b.value.codigo) {
                    return 1;
                } else if (a.value.codigo && b.value.codigo) {
                    if (a.value.codigo.substr(0, 2).toUpperCase() === 'FS') {
                        if (b.value.codigo.substr(0, 2).toUpperCase() === 'FS') {
                            if (!a.value.descripcion && !b.value.descripcion) {
                                return a.value.codigo.localeCompare(b.value.codigo);
                            } else if (a.value.descripcion && !b.value.descripcion && (a.value.descripcion.toLowerCase().includes('rueda') || a.value.descripcion.toLowerCase().includes('caja'))) {
                                return -1;
                            } else if (!a.value.descripcion && b.value.descripcion && (b.value.descripcion.toLowerCase().includes('rueda') || b.value.descripcion.toLowerCase().includes('caja'))) {
                                return 1;
                            } else if (a.value.descripcion && b.value.descripcion) {
                                if (a.value.descripcion.toLowerCase().includes("rueda")) {
                                    if (b.value.descripcion.toLowerCase().includes("rueda")) {
                                        return a.value.codigo.localeCompare(b.value.codigo);
                                    } else {
                                        return -1;
                                    }
                                } else if (a.value.descripcion.toLowerCase().includes("caja")) {
                                    if (b.value.descripcion.toLowerCase().includes("rueda")) {
                                        return 1;
                                    } else if (b.value.descripcion.toLowerCase().includes("caja")) {
                                        return a.value.codigo.localeCompare(b.value.codigo);
                                    } else {
                                        return -1;
                                    }
                                } else {
                                    if (b.value.descripcion.toLowerCase().includes("rueda") || b.value.descripcion.toLowerCase().includes("caja")) {
                                        return -1;
                                    } else {
                                        return a.value.codigo.localeCompare(b.value.codigo);
                                    }
                                }
                            } else {
                                return a.value.codigo.localeCompare(b.value.codigo);
                            }
                        } else {
                            return -1;
                        }
                    } else if (a.value.codigo.substr(0, 2).toUpperCase() === 'FD') {
                        if (b.value.codigo.substr(0, 2).toUpperCase() === 'FS') {
                            return 1;
                        } else if (b.value.codigo.substr(0, 2).toUpperCase() === 'FD') {
                            return a.value.codigo.localeCompare(b.value.codigo);
                        } else {
                            return -1;
                        }
                    } else if (a.value.codigo.substr(0, 2).toUpperCase() === 'FA') {
                        if (b.value.codigo.substr(0, 2).toUpperCase() === 'FS' || b.value.codigo.substr(0, 2).toUpperCase() === 'FD') {
                            return 1;
                        } else if (b.value.codigo.substr(0, 2).toUpperCase() === 'FA') {
                            return a.value.codigo.localeCompare(b.value.codigo);
                        } else {
                            return -1;
                        }
                    } else {
                        if (b.value.codigo.substr(0, 2).toUpperCase() === 'FS' || b.value.codigo.substr(0, 2).toUpperCase() === 'FD' || b.value.codigo.substr(0, 2).toUpperCase() === 'FA') {
                            return 1;
                        } else {
                            return a.value.codigo.localeCompare(b.value.codigo);
                        }
                    }
                } else {
                    return 0;
                }
            }
        }
    }
})();
