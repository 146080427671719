(function() {
    'use strict';

    angular
        .module('mlgomasApp')
        .controller('MainController', MainController);

    MainController.$inject = [];

    function MainController () {
        var vm = this;

        vm.applyClass = function (className, elementID) {
            $('#'+elementID).addClass(className);
        };
        vm.removeClass = function (className, elementID) {
            $('#'+elementID).removeClass(className);
        };
    }
})();
