(function() {
    'use strict';

    angular
        .module('mlgomasApp')
        .controller('ElementoDeleteController',ElementoDeleteController);

    ElementoDeleteController.$inject = ['$uibModalInstance', 'entity', 'Elemento'];

    function ElementoDeleteController($uibModalInstance, entity, Elemento) {
        var vm = this;

        vm.elemento = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Elemento.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
