(function() {
    'use strict';

    angular
        .module('mlgomasApp')
        .controller('ContactoController', ContactoController);

    ContactoController.$inject = ['$timeout', 'SendMessage'];

    function ContactoController($timeout, SendMessage) {

        var vm = this;

        vm.email = null;
        vm.send = send;
        window.scrollTo(0,0);

        function send() {
            SendMessage.send(vm.email, function () {
                vm.email = null;
                vm.sent = true;
                $timeout(function () {
                    vm.sent = false;
                }, 3000);
            });
        }
    }
})();
