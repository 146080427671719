(function() {
    'use strict';

    angular
        .module('mlgomasApp')
        .controller('PedidoController', PedidoController);

    PedidoController.$inject = ['$timeout', 'SendPedido', '$rootScope'];

    function PedidoController($timeout, SendPedido, $rootScope) {

        var vm = this;
        vm.pedidoSend = false;
        vm.sendEmail = sendEmail;
        vm.removeFuelleFromCarrito = removeFuelleFromCarrito;
        vm.removeKitFromCarrito = removeKitFromCarrito;
        vm.cliente = {};

        (window).scrollTo(0,0);

        if($rootScope.carrito != null && ($rootScope.carrito.fuelleDTOs.length != 0 || $rootScope.carrito.kitDTOs.length != 0)){
            vm.carrito = angular.copy($rootScope.carrito);
        }else{
            vm.carrito = {
                fuelleDTOs: [],
                kitDTOs: []
            };
            console.log(vm.carrito);
        }

        function removeFuelleFromCarrito(obj){
            vm.carrito.fuelleDTOs = $.grep(vm.carrito.fuelleDTOs, function (o) {
                if(o.id != obj.id){
                    return o;
                }
            });

            $rootScope.carrito = angular.copy(vm.carrito);
            $.notify("Eliminado del pedido.", "error");
        }
        function removeKitFromCarrito(obj){
            vm.carrito.kitDTOs = $.grep(vm.carrito.kitDTOs, function (o) {
                if(o.id != obj.id){
                    return o;
                }
            });

            $rootScope.carrito = angular.copy(vm.carrito);
            $.notify("Eliminado del pedido.", "error");
        }

        function sendEmail() {
            var emailDTO = {
                fuelleDTOs: vm.carrito.fuelleDTOs,
                kitPedidoDTOs: vm.carrito.kitDTOs,
                clienteDTO: vm.cliente
            };
            vm.saving = true;
            SendPedido.send(emailDTO, function () {
                vm.pedidoSend = false;
                vm.pedidoSent = true;
                vm.saving = false;
                vm.cliente = null;

                $timeout(function () {
                    vm.pedidoSent = false;
                }, 6000)
            },function () {
                vm.errorEmail = true;
            });
        }
    }
})();
