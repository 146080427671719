(function() {
    'use strict';

    angular
        .module('mlgomasApp')
        .controller('FuelleModeloDetailController', FuelleModeloDetailController);

    FuelleModeloDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'FuelleModelo', 'Fuelle', 'Modelo'];

    function FuelleModeloDetailController($scope, $rootScope, $stateParams, previousState, entity, FuelleModelo, Fuelle, Modelo) {
        var vm = this;

        vm.fuelleModelo = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mlgomasApp:fuelleModeloUpdate', function(event, result) {
            vm.fuelleModelo = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
