angular.module('mlgomasApp')
    .directive('displayImage',['WebImageGetByCode', function(WebImageGetByCode) {
        return {
            restrict: 'AEC',
            scope: {
                code: "@"
            },
            link: linkFunction
        };

        function linkFunction(scope, element, attr) {
             WebImageGetByCode.get({code : attr.code}, function (data) {
                 scope.image = data;
                 var template = '<img class="img-responsive"\n' +
                     '     data-ng-src="data:' + scope.image.imageContentType + ';base64,'+ scope.image.image +'"' +
                     '     src="data:' + scope.image.imageContentType + ';base64,'+ scope.image.image +'"' +
                     '/>';
                 element.html(template);
             });
        }
    }]);
