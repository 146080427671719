(function() {
    'use strict';
    angular
        .module('mlgomasApp')
        .factory('WebImage', WebImage)
        .factory('WebImageGetByCode', WebImageGetByCode);

    WebImage.$inject = ['$resource'];
    WebImageGetByCode.$inject = ['$resource'];

    function WebImage ($resource) {
        var resourceUrl =  'api/web-images/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    function WebImageGetByCode ($resource) {
        var resourceUrl =  'api/web-images/getOneBy/:code';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
