(function() {
    'use strict';

    angular
        .module('mlgomasApp')
        .controller('HomepagecarouselDeleteController',HomepagecarouselDeleteController);

    HomepagecarouselDeleteController.$inject = ['$uibModalInstance', 'entity', 'Homepagecarousel'];

    function HomepagecarouselDeleteController($uibModalInstance, entity, Homepagecarousel) {
        var vm = this;

        vm.homepagecarousel = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Homepagecarousel.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
