(function() {
    'use strict';

    angular
        .module('mlgomasApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$timeout', '$scope', 'Principal', 'LoginService', '$state', 'Homepagecarousel'];

    function HomeController ($timeout, $scope, Principal, LoginService, $state, Homepagecarousel) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.carouselLoeaded = false;
        vm.login = LoginService.open;
        vm.register = register;
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        window.scrollTo(0,0);

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;

                //Si no esta autentificado trae las imagenes del carousel
                if(!vm.isAuthenticated()){
                    Homepagecarousel.query(function (data) {
                        vm.carouselLoeaded = true;
                        vm.images = data;
                    });
                }
            });
        }
        function register () {
            $state.go('register');
        }
    }
})();
