(function() {
    'use strict';
    angular
        .module('mlgomasApp')
        .factory('Fuelle', Fuelle)
        .factory('FindAllCompleteFuelle', FindAllCompleteFuelle)
        .factory('FindAllWithoutPagination', FindAllWithoutPagination)
        .factory('FindAllCompleteFuelleWithoutPagination', FindAllCompleteFuelleWithoutPagination);

    Fuelle.$inject = ['$resource', 'DateUtils'];
    FindAllCompleteFuelle.$inject = ['$resource', 'DateUtils'];
    FindAllWithoutPagination.$inject = ['$resource', 'DateUtils'];
    FindAllCompleteFuelleWithoutPagination.$inject = ['$resource', 'DateUtils'];

    function Fuelle ($resource, DateUtils) {
        var resourceUrl =  'api/fuelles/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.creationDate = DateUtils.convertDateTimeFromServer(data.creationDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    function FindAllCompleteFuelle ($resource) {
        var resourceUrl =  'api/fuelles/findAllComplete/byFilter';

        return $resource(resourceUrl, {}, {
            'query': { method: 'POST', isArray: true}
        });
    }
    function FindAllWithoutPagination ($resource) {
        var resourceUrl =  'api/fuelles/getAllWithoutPagination';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
    function FindAllCompleteFuelleWithoutPagination ($resource) {
        var resourceUrl =  'api/fuelles/findAllComplete/byFilter/withoutPagination';

        return $resource(resourceUrl, {}, {
            'query': { method: 'POST', isArray: true}
        });
    }
})();
