(function() {
    'use strict';

    angular
        .module('mlgomasApp')
        .controller('ModeloDetailController', ModeloDetailController);

    ModeloDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Modelo', 'Marca'];

    function ModeloDetailController($scope, $rootScope, $stateParams, previousState, entity, Modelo, Marca) {
        var vm = this;

        vm.modelo = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('mlgomasApp:modeloUpdate', function(event, result) {
            vm.modelo = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
