(function() {
    'use strict';

    angular
        .module('mlgomasApp')
        .controller('HomepagecarouselDetailController', HomepagecarouselDetailController);

    HomepagecarouselDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'Homepagecarousel'];

    function HomepagecarouselDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, Homepagecarousel) {
        var vm = this;

        vm.homepagecarousel = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('mlgomasApp:homepagecarouselUpdate', function(event, result) {
            vm.homepagecarousel = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
